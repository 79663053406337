<template>
<div id="page-marcas-modelos">
  <div>
    <v-breadcrumbs :items="breadcrumbs">
      <v-icon slot="divider">forward</v-icon>
    </v-breadcrumbs>
  </div>
  <v-container fluid fill-height>
    <v-row align-center justify-center>
      <v-col>
        <base-material-card color="primary" icon="library_books" title="LOTES EJEMPLO" class="elevation-1 px-5 py-3">
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn dark small color="secondary" slot="activator" @click.native="openModal(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>add</v-icon> Nuevo Registro en Lotes
            </v-btn>
            <v-btn dark small color="primary" slot="activator" @click.native="openModalAjuste(1)" title="Agregar Registro" v-tippy>
              <v-icon dark>swap_horizontal_circle</v-icon> Aumentar/Disminuir Cantidad
            </v-btn>
            <v-btn dark small color="green darken-2" slot="activator" @click.native="openModalConsulta(1)" title="Consultar Existencia" v-tippy>
              <v-icon dark>search</v-icon> Consultar Existencia
            </v-btn>
          </v-card-actions>

          <v-card-text>
            <div id="datagrid">
              <v-btn x-small @click="getMarcas()"><v-icon>refresh</v-icon></v-btn>
              <v-client-table ref="vuetable" :data="registros.items" :columns="columns" :options="options" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>

                    <template slot="actions" slot-scope="props">
                        <v-speed-dial v-model="props.row.id" direction="left" open-on-hover>
                            <template v-slot:activator>
                                <v-btn x-small fab dark color="primary">
                                    <v-icon v-if="fab[props.row.id]">mdi-close</v-icon>
                                    <v-icon v-else>reorder</v-icon>
                                </v-btn>
                            </template>
                            <v-btn x-small fab dark color="blue" @click.native="getRegistro(props.row._id)" title="Editar Registro" v-tippy>
                                <v-icon>edit</v-icon>
                            </v-btn>
                            <v-btn x-small fab dark color="pink" @click.native="deleteRegistro(props.row._id, props.row._rev )" title="Eliminar Registro" v-tippy>
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </v-speed-dial>
                    </template>
                </v-client-table>
            </div>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>

  <v-dialog v-model="modal" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>{{ update ? "Actualizar" : "Nuevo" }} Registro Lote</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modal = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" lazy-validation>
          <v-container grid-list-md @keyup.enter="update ? updateRegistro() : saveRegistro()">
            <v-row>
              <v-col sm="6" md="6" lg="6">
                <v-autocomplete v-model="model.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Sucursal" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                <v-autocomplete v-model="model.id_articulo" :items="articulos" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="3" lg="3">
                <v-text-field label="Cantidad" v-model="model.cantidad" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="12" md="3" lg="3">
                <v-text-field v-if="update" label="Actual" v-model="model.actual"></v-text-field>
              </v-col>
              <v-col sm="12" md="3" lg="3">
                <v-text-field label="Comprometido" v-model="model.comprometido"></v-text-field>
              </v-col>
              <v-col sm="12" md="3" lg="3">
                <v-text-field label="Precio Compra" v-model="model.precio_compra"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="6" lg="6">
                      <v-menu offset-x ref="menu1" v-model="menu1" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-on="on" v-model="model.fecha_caducidad" label="Fecha Caducidad" color="secondary" prepend-icon="event"
                            @blur="model.fecha_caducidad = parse_date(model.fecha_caducidad)">
                          </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_caducidad" color="secondary" scrollable locale="es-mx" @input="menu1 = false">
                          <v-spacer />

                          <v-btn color="secondary" x-small @click="menu1 = false">
                            Cancelar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
                <v-col sm="12" md="6" lg="6">
                      <v-menu offset-x ref="menu2" v-model="menu2" :close-on-content-click="false" transition="scale-transition" min-width="290px" offset-y>
                        <template v-slot:activator="{ on }">
                          <v-text-field v-on="on" v-model="model.fecha_entrada" label="Fecha Entrada" color="secondary" prepend-icon="event"
                            @blur="model.fecha_entrada = parse_date(model.fecha_entrada)" :rules="[rules.required]">
                          </v-text-field>
                        </template>

                        <v-date-picker v-model="model.fecha_entrada" color="secondary" scrollable locale="es-mx" @input="menu2 = false">
                          <v-spacer />

                          <v-btn color="secondary" x-small @click="menu2 = false">
                            Cancelar
                          </v-btn>
                        </v-date-picker>
                      </v-menu>
                    </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modal = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="update ? updateRegistro() : saveRegistro()">
          <v-icon>done</v-icon> {{ update ? "Actualizar" : "Guardar" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL AJUSTE -->
  <v-dialog v-model="modalAjuste" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Nuevo Ajuste de Lote</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalAjuste = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formajuste" lazy-validation>
          <v-container grid-list-md>
            <v-row>
              <v-col sm="6" md="6" lg="6">
                <v-autocomplete v-model="modelAjuste.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Sucursal" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                <v-autocomplete v-model="modelAjuste.id_articulo" :items="articulos" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="12" md="4" lg="4">
                <v-text-field label="Cantidad" v-model="modelAjuste.cantidad" hint="Si se disminuye la cantidad debe ser negativa" persistent-hint :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col sm="12" md="4" lg="4">
                <v-switch
                    v-model="modelAjuste.es_super"
                    :label="modelAjuste.es_super==1?'Es Super? Si':'Es Super? No'"
                    true-value="1" false-value="0"
                ></v-switch>
              </v-col>
              <v-col sm="12" md="4" lg="4">
                <v-text-field label="Id Compra" v-model="modelAjuste.id_compra" hint="Opcional, si se ingresa se afectara al lote con ese id_compra" persistent-hint></v-text-field>
              </v-col>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalAjuste = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="ajustar()">
          <v-icon>done</v-icon> Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  <!-- MODAL CONSULTA -->
  <v-dialog v-model="modalConsulta" max-width="50%">
    <v-card>
      <v-card-title>
        <v-card class="primary white--text titulomodal">
          <v-icon class="white--text">{{ update ? "edit" : "add" }}</v-icon>&nbsp;
          <span class="subheading">
            <strong>Consulta Existencias</strong>
          </span>
        </v-card>
        <v-spacer></v-spacer>
        <v-btn icon @click.native="modalConsulta = false" class="close_modal">
          <v-icon class="white--text">cancel</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="formconsulta" lazy-validation>
          <v-container grid-list-md>
            <v-row>
                <v-col sm="6" md="6" lg="6">
                <v-autocomplete v-model="modelConsulta.id_articulo" :items="articulos" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id"
                    label="Artículo" :rules="[rules.required]">
                </v-autocomplete>
              </v-col>
              <v-col sm="6" md="6" lg="6">
                <v-autocomplete v-model="modelConsulta.id_sucursal" :items="sucursales" :hide-no-data="true"
                    :hide-selected="true" item-text="nombre" item-value="_id" clearable
                    label="Sucursal">
                </v-autocomplete>
              </v-col>
            </v-row>
            <v-row>
                <v-client-table ref="vuetable" :data="existencias" :columns="columnsExistencias" :options="optionsExistencias" class="elevation-2 pa-2">
                    <template slot="noResults">
                        <v-alert :value="true" icon="info" color="info">No se encontró ningún registro</v-alert>
                    </template>
                    <template slot="id_sucursal" slot-scope="props">
                                {{getNombreSucursal(props.row.id_sucursal)}}
                    </template>
                    <template slot="id_articulo" slot-scope="props">
                        {{getNombreArticulo(props.row.id_articulo)}}
                    </template>
                </v-client-table>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click.native="modalConsulta = false">
          <v-icon>cancel</v-icon> Cancelar
        </v-btn>
        <v-btn color="success" @click.native="consultar()">
          <v-icon>done</v-icon> Consultar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

</div>
</template>

<script>
export default {
  mounted: function() {

    this.getMarcas();
  },
  created: function() {
      this.getSucursales();
      this.getArticulos();
  },
  data() {
    return {
      fab: [],
      modalAjuste:false,
      modalConsulta:false,
      menu1:false,
      menu2:false,
      name: "datagrid",
      columns: [
        "nombre_sucursal",
        "nombre_articulo",
        "cantidad", "actual", "comprometido", "precio_compra", "fecha_entrada","fecha_caducidad",
        "actions"
      ],
      options: {
        headings: {
          actions: "Operaciones"
        },

      },
      breadcrumbs: [{
          text: "Inicio",
          disabled: false,
          to: "/index"
        },
        {
          text: "Ejemplo Lotes",
          href: ""
        }
      ],

        existencias:[],
        columnsExistencias: [
            "id_sucursal",
            "id_articulo",
            "existencia",
        ],
        optionsExistencias: {},

      modal: false,

      update: false,

      model: {
        _id: "",
        _rev: "",
        type:"lotes",
        id_sucursal:"",
        id_articulo:"",
        id_compra:"123abc",
        cantidad:"",
        actual:"",
        comprometido:"",
        precio_compra:"",
        fecha_caducidad:"",
        fecha_entrada:"",
      },
      modelAjuste:{
        id_sucursal:"",
        id_articulo:"",
        id_compra:"123abc",
        cantidad:"",
        es_super:0,
      },
      modelConsulta:{
        id_sucursal:"",
        id_articulo:"",
      },
      rules: {
        required: v => !!v || "Este campo es requerido",
      },
      search: "",
      registros: {
        selected: [],
        items: []
      },
      sucursales:[],
      articulos:[],

    };
  },
  methods: {
      parse_date(date) {
      if (!date) return null;
      return moment(String(date)).format("YYYY-MM-DD");
    },
    getNombreSucursal: function(id_sucursal){
        var filter = this.sucursales.find(e => e._id==id_sucursal);
        if(filter)
            return filter.nombre;
        else
            return ""
    },
    getNombreArticulo: function(id_articulo){
        var filter = this.articulos.find(e => e._id==id_articulo);
        if(filter)
            return filter.nombre;
        else
            return ""
    },
      getSucursales: function () {
            let data = {
                    "selector":{"type":"sucursales"},
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0)
                        this.sucursales = response.data.docs;
                    else
                        this.sucursales = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener las sucursales.",
                        footer: ""
                    });

                });
        },
        getArticulos: function () {
            let data = {
                    "selector":{"type":"articulos"},
                    /*"selector": {
                        "year": {
                        "$eq": 2001
                        }
                    },*/
                    "sort": [
                        "nombre"
                    ],
                    /*"fields": [
                        "nombre", "estatus"
                    ]*/
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0){
                        this.articulos = response.data.docs;
                        this.articulos.forEach(function(e){
                            e.tiene_impuestos = e.tiene_impuestos==1?"Si":"No";
                            e.tiene_grupo = e.tiene_grupo==1?"Si":"No";
                        });
                    }else
                        this.articulos = [];

                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los articulos.",
                        footer: ""
                    });

                });
        },
    openModal: function(type) {
      if (type === 1) {
        this.update = false;

      } else {
        this.update = true;
      }
      this.modal = true;
    },
    openModalAjuste: function(type) {
      if (type === 1) {
        this.update = false;

      } else {
        this.update = true;
      }
      this.modalAjuste = true;
    },
    openModalConsulta: function(type) {
      if (type === 1) {
        this.update = false;

      } else {
        this.update = true;
      }
      this.modalConsulta = true;
    },

    validaNombre: function(val) {
      if (val != null) {
        if (this.esCampoUnico("nombre", val) == false)
          return "El nombre " + val + " ya se encuentra registrado.";
      }
      return true;
    },

    esCampoUnico: function(campo, valor) {
      var filter = [];
      if (this.update) {
        var filter = this.registros.items.find(e => e[campo].toString().trim() == valor.toString().trim() && e._id != this.model._id);
      } else {
        var filter = this.registros.items.find(e => e[campo].toString().trim() == valor.toString().trim());
      }
      if (filter) {
        return false;
      }
      return true;
    },

    getMarcas: function() {
 window.dialogLoader.show('Espere un momento por favor..');

            let data = {
                    "selector":{"type":"lotes"},
                    /*"selector": {
                        "year": {
                        "$eq": 2001
                        }
                    },*/
                    "sort": [
                        "id_sucursal","id_articulo"
                    ],
                    /*"fields": [
                        "nombre", "estatus"
                    ]*/
                    };

            window.axios
                .post(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/_find/',data)
                .then(response => {
                    if (response.data.docs.length > 0) {
                        this.registros.items = response.data.docs;
                        this.registros.items.forEach( e =>{
                            e.nombre_sucursal = this.getNombreSucursal(e.id_sucursal);
                            e.nombre_articulo = this.getNombreArticulo(e.id_articulo);
                        });
                    } else
                        this.registros.items = [];
                    window.dialogLoader.hide();
                })
                .catch(error => {
                    this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al obtener los registros.",
                        footer: ""
                    });
                    window.dialogLoader.hide();
                });
    },

    getRegistro: function(id_registro) {

      window.axios
        .get(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + id_registro)
        .then(response => {
          this.model = response.data;
          this.model.fecha_caducidad = (this.model.fecha_caducidad!=null && this.model.fecha_caducidad!="") ? window.moment(this.model.fecha_caducidad).format("YYYY-MM-DD") : "";
          this.model.fecha_entrada = window.moment(this.model.fecha_entrada).format("YYYY-MM-DD");
          this.openModal(2);
        })
        .catch(error => {

          this.$swal({
            type: "error",
            title: "¡Operación no Permitida!",
            text: "Ocurrió un error al obtener el registro",
            footer: ""
          });
        });
    },
    saveRegistro: function() {
      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');

        //PARA REGISTRAR ENTRADA DE INVENTARIO
        //Nota, se busca por sucursal, articulo, compra, precio_compra, fecha entrada, fecha caducidad(opcional)
        //si encuentra un registro con todo eso aumenta la cantidad, sino crea un registro nuevo
        let data = {
          id_sucursal: this.model.id_sucursal,  //Requerido
          id_articulo: this.model.id_articulo,  //Requerido
          id_compra: this.model.id_compra,      //Requerido
          cantidad: parseFloat(this.model.cantidad), //Requerido
          precio_compra: parseFloat(this.model.precio_compra), //Requerido
          fecha_caducidad: this.model.fecha_caducidad, //Opcional
          fecha_entrada: this.model.fecha_entrada,  //Requerido
        };
        //console.log(data);

        window.funciones_lotes.nuevaEntradaInventario(data).then(result =>{
            window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
              color: 'success'
            });
            this.modal = false;
            this.getMarcas();
            //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}
        }).catch(err => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro.",
                    footer: err
                });
                console.log(err);
        }).then(() => {
            window.dialogLoader.hide();
          });

      }
    },
    ajustar: function() {

      if (this.$refs.formajuste.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');
        var lotes = [];

        //PARA AUMENTAR/DISMINUIR CANTIDAD EN LOTE
            //NOTA: Si el id_compra es diferente de null, va a buscar esa compra para afectarla directamente
            // sino ya buscara por fecha_caducidad (si el producto tiene), y fecha de entrada la menor que todavía tenga actual>0

        let data = {
          id_sucursal: this.modelAjuste.id_sucursal,  //Requerido
          id_articulo: this.modelAjuste.id_articulo,  //Requerido
          id_compra: null, //this.modelAjuste.id_compra,  //Opcional
          cantidad: parseFloat(this.modelAjuste.cantidad), //Requerido
          es_super: parseInt(this.modelAjuste.es_super), //Requerido
        };

        window.funciones_lotes.ajusteInventario(data).then(result =>{
          lotes = result;
          //console.log(result); //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}
          if (data.es_super == 1 || data.cantidad > 0){
            //Si es super se afecta directamente el actual
            window.dialogLoader.showSnackbar('El registro se creó correctamente..', {
              color: 'success'
            });
            this.modalAjuste = false;
            this.getMarcas();
          } else {
            //Si NO es super se resta del actual y se pasa al comprometido
            //Preguntar si finaliza o cancela la venta, si finaliza se afecta el comprometido, si se cancela se devuelve el comprometido al actual
            this.$swal({
              type: "info",
              title: "",
              text: "¿Desea finalizar la venta?",
              footer: "",
              showCancelButton: true,
              cancelButtonColor: "#d33",
              confirmButtonColor: "#3085d6",
              confirmButtonText: "Finalizar",
              cancelButtonText: "Cancelar"
            }).then(result => {
              if (result.value) {
                 //Restar el inventario comprometido con los lotes del result
                 window.funciones_lotes.finalizarComprometido(lotes).then(result =>{
                   this.$swal({
                      type: "success",
                      title: "Venta Terminada",
                      text: "",
                      footer: ""
                  });
                  console.log(result);
                 }).catch( error => {
                   this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al finalizar la venta",
                        footer: error
                    });
                 }).then(() => {
                   this.modalAjuste = false;
                  this.getMarcas();
                 });

              } else {
                //Regresar el inventario comprometido al actual
                window.funciones_lotes.cancelarComprometido(lotes).then(result =>{
                   this.$swal({
                      type: "success",
                      title: "Venta Cancelada",
                      text: "",
                      footer: ""
                  });
                  console.log(result);
                 }).catch( error => {
                   this.$swal({
                        type: "error",
                        title: "¡Operación no Permitida!",
                        text: "Ocurrió un error al cancelar la venta",
                        footer: error
                    });
                 }).then(() => {
                   this.modalAjuste = false;
                  this.getMarcas();
                 });
              }
            });
          }


        }).catch(err => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al guardar el registro.",
                    footer: err
                });
                console.log(err);
        }).then(() => {
            window.dialogLoader.hide();
          });

      }
    },
    getLotes: function(){
       var lotes = [
         {"lote":"4b96d6205d8fb476e1beaf3757000773","cantidad":1},
         {"lote":"4b96d6205d8fb476e1beaf37570050bb","cantidad":1},
         {"lote":"4b96d6205d8fb476e1beaf3757005d64","cantidad":1},
         {"lote":"4b96d6205d8fb476e1beaf37570050bb","cantidad":1},
       ];
       window.funciones_lotes.getLotes(lotes).then(result =>{
            //result trae array de lotes {"lote":id_lote, "cantidad":cantidad}
            return true;

        }).catch(err => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al getLotes.",
                    footer: err
                });
        });
    },
    consultar: function(){
        if (this.$refs.formconsulta.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = {
            id_articulo: this.modelConsulta.id_articulo,  //Requerido
            id_sucursal: this.modelConsulta.id_sucursal!=null && this.modelConsulta.id_sucursal!="" ? this.modelConsulta.id_sucursal : null,  //Opcional
        };

        window.funciones_lotes.consultaExistencia(data).then(result =>{
            window.dialogLoader.showSnackbar('La consulta se realizó correctamente..', {
              color: 'success'
            });

            this.existencias= result; //[{"id_articulo": "", "id_sucursal":"", "existencia":1}]

        }).catch(err => {
                this.$swal({
                    type: "error",
                    title: "¡Operación no Permitida!",
                    text: "Ocurrió un error al consultar.",
                    footer: err
                });
        }).then(() => {
            window.dialogLoader.hide();
          });

      }
    },
    updateRegistro: function() {

      if (this.$refs.form.validate()) {

        window.dialogLoader.show('Espere un momento por favor..');

        let data = this.model;
        data.cantidad = parseFloat(this.model.cantidad);
        data.actual = parseFloat(this.model.actual);
        data.comprometido = parseFloat(this.model.comprometido);
        data.precio_compra= parseFloat(this.model.precio_compra);
        data.fecha_caducidad= (this.model.fecha_caducidad!=null && this.model.fecha_caducidad!="") ? window.moment(this.model.fecha_caducidad).format("YYYY-MM-DDTHH:mm:ss") : "";
        data.fecha_entrada= window.moment(this.model.fecha_entrada).format("YYYY-MM-DDTHH:mm:ss");


        window.axios
          .put(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + '/' + this.model._id + '?conflicts=true', data)
          .then(response => {
            window.dialogLoader.showSnackbar('El registro se actualizó correctamente..', {
              color: 'success'
            });
            this.getMarcas();
          })
          .catch(error => {
            window.dialogLoader.showSnackbar('Ocurrió un error al actualizar el registro..', {
              color: 'error'
            });
          }).then(() => {
            this.modal = false;
            window.dialogLoader.hide();
          });
      }
    },
    deleteRegistro: function(_id, _rev) {
      this.$swal({
        type: "info",
        title: "Cuidado",
        text: "¿Está seguro de eliminar el registro?",
        footer: "",
        showCancelButton: true,
        cancelButtonColor: "#d33",
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar"
      }).then(result => {
        if (result.value) {
          window.dialogLoader.show('Espere un momento por favor..');
          window.axios
            .delete(process.env.VUE_APP_COUCHDB_URL + '/' + process.env.VUE_APP_COUCHDB_DATABASE + "/" + _id + "?rev=" + _rev)
            .then(response => {
              window.dialogLoader.showSnackbar('El registro se eliminó correctamente..', {
                color: 'success'
              });
              this.getMarcas();
            })
            .catch(error => {
              window.dialogLoader.showSnackbar('Ocurrió un error al eliminar el registro..', {
                color: 'error'
              });
              window.dialogLoader.hide();
            });

        }

      });
    },

  }
};
</script>
